@charset "utf-8";
/* CSS Document */ 

* { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; margin:0; padding:0; }
*:before, *:after { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
body { margin:0; padding:0; font-family: 'Poppins'; color: #241C15; -webkit-overflow-scrolling: touch;}

#root {
	overflow: hidden;
	width: 100% !important;
}

:focus { outline:none;}
ul, li, ol { list-style:none; padding: 0; margin: 0;}
a { text-decoration:none; -webkit-transition:0.5s; color: #494949; transition:0.5s;}
input[type="button"],input[type="submit"] { text-decoration:none; -webkit-transition:0.5s; transition:0.5s;}
a:hover{color:#0072ca; text-decoration: none; outline: none;}
a:focus {text-decoration: none; outline: none;}
a img, img { border:none; vertical-align:middle; max-width:100%; width:auto}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Poppins-Bold';
	margin: 0px;
	padding: 0px;
	color: #222;
}
p {
	font-family: 'Poppins';
	color: #222;
	font-size: 15px;
	margin-bottom: 15px;
}

:before, :after { -webkit-box-sizing:border-box; -moz-box-sizing:border-box; box-sizing:border-box }


input,
button,
select,
textarea { font-family: inherit; font-size: inherit; line-height: inherit; }

button, 
html input[type="button"], 
input[type="reset"], 
input[type="submit"] { cursor: pointer;  outline: none !important; text-decoration: none !important;}

button::-moz-focus-inner, 
input::-moz-focus-inner {  padding: 0;  border: 0; }

input[type="checkbox"], 
input[type="radio"] { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; padding: 0; }

input[type="search"],
input[type="text"],
textarea { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box;  }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {  -webkit-appearance: none;}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {-webkit-appearance: none;   margin: 0;}


table { border-spacing: 0; border-collapse: collapse; }

.row:before, .row:after {display: inline-block !important;}

.container {max-width: 1200px; width: 100%; margin:0 auto; padding: 0px 15px;}
.container:before, 
.container:after { display:table; content:"";}
.container:after { clear:both;}

.container-fluid {max-width: 100%; width: 100%; margin:0 auto; padding: 0px 20px;}
.container-fluid:before, 
.container-fluid:after { display:table; content:"";}
.container-fluid:after { clear:both;}



.btn.btn-green {
	background: #2aac8e;
	color: #fff;
	font-size: 16px;
	padding: 10px 20px;
	border: 1px solid #2aac8e;
	font-weight: 400;
	border-radius: 50px;
	min-width: 170px;
	transition: 0.5s all ease-in-out;
}
.btn.btn-green:hover {
	box-shadow: 0px 1px 6px #2aac8e;
}

.btn.btn-red {
	background: #dc3545;
	color: #fff;
	font-size: 16px;
	padding: 10px 20px;
	border: 1px solid #dc3545;
	font-weight: 400;
	border-radius: 50px;
	min-width: 170px;
	transition: 0.5s all ease-in-out;
}
.btn.btn-red:hover {
	box-shadow: 0px 1px 6px #dc3545;
}

.btn.btn-yellow {
	background: #ffa426;
	color: #fff;
	font-size: 16px;
	padding: 10px 20px;
	border: 1px solid #ffa426;
	font-weight: 400;
	border-radius: 50px;
	min-width: 170px;
	transition: 0.5s all ease-in-out;
}
.btn.btn-yellow:hover {
	box-shadow: 0px 1px 6px #ffa426;
}

.btn.btn-white {
	background: #fff;
	border-radius: 45px;
	padding: 8px 20px;
	color: #2aac8e;
	font-size: 16px;
}
.btn svg, .btn i {
	margin-right: 5px;
	display: inline-flex;
}

.auth-block {
	display: flex;
	width: 100%;
	min-height: 100vh;
}
.auth-imgshap {
	flex: 0 0 60%;
	max-width: 60%;
	position: relative;
	display: block;
}
.auth-imgshap::before {
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	opacity: .3;
	background: radial-gradient(#d2f1df,#d3d7fa,#bad8f4) 0% 0%/400% 400%;
	animation: 15s ease 0s infinite normal none running gradient;
	z-index: -1;
}
.auth-img img {
	max-width: 500px;
	width: 100%;
	display: block;
}
.auth-img {
	padding: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}
.auth-froms {
	flex: 0 0 40%;
	max-width: 40%;
	position: relative;
	padding: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.forms_boxs {
	flex: 0 0 100%;
	max-width: 100%;
}

.titles p {
	color: #999999;
}
.titles h2 {
	font-size: 30px;
	margin-bottom: 8px;
	color: #2aac8e;
}
.form-group {
	display: block;
	margin-bottom: 16px;
}
.control.has-icon {
	width: 100%;
	box-sizing: border-box;
	clear: both;
	font-size: 1rem;
	position: relative;
	text-align: inherit;
}
.input.v-input {
	align-items: center;
	border: 1px solid #dbdbdb;
	box-shadow: none;
	display: inline-flex;
	font-family: 'Poppins'; 
	color: #000;
	font-size: 16px;
	justify-content: flex-start;
	line-height: 1.5;
	position: relative;
	vertical-align: top;
	height: 60px;
	border-radius: 10px;
	padding-inline-start: 55px;
	transition: all .3s;
	width: 100%;
	max-width: 100%;
	background-color: #fff;
	padding: 25px 15px 15px 50px;
	
}
.label.auth-label {
	position: absolute;
	top: 6px;
	inset-inline-start: 55px;
	font-size: 13px;
	color: #000;
	z-index: 2;
	transition: all .3s;
	left: 50px;
	opacity: 0.5;
	margin: 0;
	display: block;
}

.select.input.v-input {
	background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	padding-right: 35px !important;
}

.input.v-input:focus-visible {
	outline-offset: 3px;
	outline-width: 1px;
	outline-style: dashed;
	outline-color: #ccc;
}
.input.v-input:focus {
	background: #fcfcfc;
	border-color: #cfcfcf;
}



.select.input.v-input:target,
.select.input.v-input:focus,
.select.input.v-input:focus-visible {
	outline-offset: 3px;
	outline-width: 1px;
	outline-style: dashed;
	outline-color: #ccc;
	background: #fcfcfc url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
	border-color: #cfcfcf;
}

.label.form-icon {
	position: absolute;
	top: 0;
	inset-inline-start: 0;
	height: 60px;
	width: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 22px;
	color: #e0e0e0;
	transition: all .3s;
}
textarea.input.v-input {
	height: 200px !important;
	resize: none !important;
	padding-top: 18px;
}
.remember-toggle input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
.remember-toggle {
	width: 65px;
	display: block;
	position: relative;
	cursor: pointer;
	font-size: 22px;
	user-select: none;
	transform: scale(.9);
}
.remember-toggle .toggler {
	position: relative;
	display: block;
	height: 34px;
	width: 61px;
	border: 2px solid #cfcfcf;
	border-radius: 100px;
	transition: all .3s;
}
.remember-toggle .toggler .active, .remember-toggle .toggler .inactive {
	position: absolute;
	top: 2px;
	inset-inline-start: 2px;
	height: 26px;
	width: 26px;
	border-radius: 50%;
	background: #000;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: translate(calc(1 * 0)) rotate(calc(1 * 0));
	transition: all .3s ease;
}
.remember-toggle .toggler .inactive {
	background: #cfcfcf;
	border-color: #cfcfcf;
	opacity: 1;
	z-index: 1;
}
.remember-toggle .toggler .active svg, .remember-toggle .toggler .inactive svg {
	color: #fff;
	height: 14px;
	width: 14px;
	stroke-width: 3px;
}
.remember-toggle input:checked ~ .toggler .active {
	opacity: 1;
}
.remember-toggle input:checked ~ .toggler .active, 
.remember-toggle input:checked ~ .toggler .inactive {
	transform: translate(calc(1 * 100%)) rotate(360deg);
}
.remember-toggle .toggler .active {
	background: #2aac8e;
	border-color: #2aac8e;
	opacity: 0;
	z-index: 0;
}
.remember-toggle input:checked ~ .toggler .inactive {
	opacity: 0;
}
.remember-me {
	color: #999;
	font-weight: 400;
	font-size: 14px;
}
.control.is-flex {
	width: 100%;
	position: relative;
	display: flex;
	align-items: center;
}
.links {
	/* margin-inline-start: auto; */
	transition: color .3s;
	color: #2aac8e;
	font-weight: 400;
	font-size: 14px;
}
.control.is-flex.space {
	justify-content: space-between;
}

.errormsg {
	color: red;
	font-size: 14px;
	line-height: normal;
}

.forms_boxs .buttonbox {
	margin-top: 40px;
}





.header-block {
	background: #2aac8e;
	display: flex;
	align-items: center;
	padding: 0;
}
.logo-box {
	flex: 0 0 230px;
	max-width: 230px;
	padding: 10px 15px;
	background: #2e7161;
}
.logo-box img {
	width: 100%;
	display: block;
	margin: 0 auto;
	max-width: 130px;
}
.rightnav {
	display: flex;
	align-items: center;
	position: relative;
	-webkit-box-flex: 1;
	flex: 1 1 auto;
	width: 1%;
	justify-content: space-between;
	padding: 10px 15px;
}
.rightnav ul {
	display: flex;
	align-items: center;
	flex: 0 0 50%;
	justify-content: space-between;
}
.rightnav ul li {
	padding: 0 15px;
}
.rightnav ul li:first-child {
	padding-left: 5px;
}
.rightnav p {
	color: #fff;
	font-size: 15px;
	margin: 0;
	line-height: 22px;
}
.rightnav h6 {
	font-size: 16px;
	color: #fff;
}




.pagecontent-box {
	display: flex;
}
.leftnav {
	min-height: calc(100vh - 62px);
	width: 100%;
	background-color: #fff !important;
	box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15) !important;
	z-index: 0;
	max-width: 230px;
	flex: 0 0 230px;
}
#nav ul li {
	display: block;
	margin-top: 1px;
}
#nav ul li a {
	display: flex;
	align-items: center;
	color: #606060;
	padding: 15px;
	font-size: 15px;
	border-right: 8px solid #fff;
}
#nav ul li a svg, #nav ul li a i {
	justify-content: flex-start;
	font-size: 18px;
	width: 18px;
	flex: 0 0 18px;
}
#nav ul li a span {
	display: block;
	line-height: 18px;
	padding-left: 10px;
}
#nav ul li a:hover, #nav ul li a.active  {
	background: #eee;
	border-color: #2aac8e;
}


.pagecontent-box {
	display: flex;
}
.contents {
	background: #edf2fc;
	position: relative;
	flex: 1 1 auto;
	width: 1%;
	padding: 20px;
}
.pagetitle {
	padding-bottom: 20px;
}
.pagetitle h1 {
	font-size: 25px;
	line-height: 25px;
	font-family: 'Poppins';
	letter-spacing: 1px;
}
.cardsbox {
	position: relative;
	display: flex;
	background-color: #fff;
	border-radius: 7px;
	box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15) !important;
	padding: 20px;
	justify-content: space-between;
	align-items: center;
	height: 100%;
}
.count-left p {
	text-transform: capitalize;
	letter-spacing: 0.5px;
	font-size: 16px;
	margin-bottom: 8px;
	font-family: 'Poppins-Medium';
}
.count-left h4 {
	color: #2aac8e;
	font-size: 24px;
}
.count-right span svg, .count-right span i {
	display: block;
	color: #66bb6a;
	font-size: 36px;
}

.col-md-4:first-child .count-right span svg,
.col-md-4:first-child .count-right span i 
{
	color: #6777ef;
}

.col-md-4:nth-child(3) .count-right span svg,
.col-md-4:nth-child(3) .count-right span i 
{
	color: #ffa426;
}



.pageloder {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	background: rgba(255,255,255,0.6);
	align-items: center;
	z-index: 11111111;
}
.pageloder img {
	max-width: 80px;
	display: block;
}


.cards {
	position: relative;
	background-color: #fff;
	border-radius: 7px;
	box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15) !important;
	display: block;
	border: none;
	margin-top: 40px;
}

.cards-hedar {
	display: flex;
	align-items: center;
	padding: 20px 15px;
	border-bottom: 1px solid #eaecf4;
	justify-content: space-between;
}
.cards-hedar h4 {
	color: #2aac8e;
	font-size: 20px;
}
.cardinfo.forms {
	padding: 15px;
	max-width: 800px;
	width: 100%;
}
.usersinfos p {
	font-size: 16px;
	margin-bottom: 15px;
	display: flex;
}
.usersinfos p b {
	color: #000;
	flex: 0 0 120px;
	max-width: 120px;
}


.notes {
	margin-top: 15px;
	margin-bottom: 30px;
}
.notes h6 {
	font-size: 15px;
	margin-bottom: 10px;
}
.credits h6 {
	font-size: 18px;
	margin-bottom: 25px;
}
.card-froms {
	padding: 15px;
}
.cards-hedar.contactus p, .cards-hedar.contactus a {
	font-size: 20px;
	margin: 0;
}

.control.is-flex .remember-toggle {
	margin-right: 10px;
}
.control.is-flex p {
	margin: 0;
}



#otpform input {
	background: #EFEFEF;
	margin: 0 10px;
	width: 50px !important;
	aspect-ratio: 16/16;
	border-radius: 7px;
	color: #000000;
	font-size: 18px;
	border: 1px solid #ccc;
	box-shadow: none !important;
	outline: none !important;
	font-family: 'Poppins';
}


.rdioboxs.d-flex .inputrdio {
	padding-left: 10px;
}
.inputrdio input {
	margin-right: 4px;
}


.buttonboxs.group {
	display: flex;
}
.buttonboxs.group .btn-red {
	margin-left: 25px;
}




#contents ol {
	list-style-type: none;
	counter-reset: item;
	margin: 0;
	padding: 0;
  }
  
  #contents ol > li {
	display: table;
	counter-increment: item;
	margin-bottom: 0.6em;
  }
  
  #contents ol > li:before {
	content: counters(item, ".") ". ";
	display: table-cell;
	padding-right: 0.6em;    
  }
  
  #contents ol > li >  ol > li {
	margin: 0;
  }
  
  #contents li > ol > li:before {
	content: counters(item, ".") " ";
  }


#heds.logo-box {
	padding: 20px 15px;
}


#contents .contents-block {
	padding: 60px 0;
}
#contents h1 {
	font-size: 40px;
	color: #000;
	margin-bottom: 20px;
}
#contents p {
	margin-bottom: 5px;
}
#contents h4 {
	margin-top: 20px;
	margin-bottom: 5px;
	color: #000;
	font-size: 22px;
}
#contents ol {
	padding-top: 5px;
}
#contents li {
	padding-top: 5px;
}

li.main span,
li.main::before {
	font-family: 'Poppins-Bold';
	color: #000;
}
b {
	font-family: 'Poppins-Bold';
	color: #000;
}

#contents p, #contents  li{
	font-family: 'Poppins';
  	color: #5a5c69;
  	font-size: 15px;
}
#contents a {
	color: #2aac8e;
}

#contents .grid {
	display: flex;
	flex-wrap: wrap;
	max-width: 400px;
}
#contents .grid li {
	flex: 0 0 50%;
	max-width: 50%;
}



.paycdr {
	background: #fff;
	max-width: 500px;
	margin: 30px auto;
	border-radius: 15px;
	box-shadow: 0 0 4px #eee;
}
.payicon {
	text-align: center;
	padding: 25px;
}
.payicon svg, .payicon i {
	color: #2aac8e;
	font-size: 80px;
	display: block;
	margin: 0 auto;
}
.paycdr p {
	text-align: center;
	font-size: 20px;
	color: #2aac8e;
}
.paycdr h4 {
	color: #000;
	font-size: 26px;
	text-align: center;
}
.payinfo {
	border-top: 2px dotted #ccc;
	margin-top: 15px;
	padding: 15px;
	padding-bottom: 10px;
}
.payinfo ul {
	display: flex;
	align-items: center;
	padding-bottom: 12px;
}
.payinfo ul li:first-child {
	flex: 0 0 190px;
	max-width: 190px;
}
.payinfo h4 {
	color: #000;
	font-size: 16px;
	text-align: left;
	font-weight: 400;
}
.payinfo p {
	color: #626465;
	font-size: 16px;
	text-align: left;
	margin: 0;
}

.paycdr.failure .payicon svg, 
.paycdr.failure .payicon i {
	color: red;
}
.paycdr.failure p {
	color: red;
}
.paycdr.failure {
	padding-bottom: 10px;
}




#emailVerified {
	background: #edf2fc;
	min-height: 100vh;
	display: flex;
	align-items: center;
}
#emailVerified  .email-block {
	background: #fff;
	position: relative;
	border-radius: 7px;
	box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15) !important;
	display: block;
	border: none;
	max-width: 500px;
	margin: 0 auto;
	overflow: hidden;
	flex: 0 0 100%;
}
#emailVerified  .cardsheds {
	display: flex;
	padding: 15px;
	justify-content: space-between;
	align-items: center;
}
#emailVerified  .cardsheds .logo {
	flex: 0 0 180px;
	max-width: 180px;
	padding-right: 15px;
}
#emailVerified  .logo img {
	display: block;
	width: 100%;
}
#emailVerified  .cardsheds h4 {
	color: #08a88a;
	font-size: 17px;
}
#emailVerified  .email-block p {
	padding: 0 15px 15px 15px;
	margin: 0;
}
#emailVerified  .imagesboxs {
	border-bottom: 1px solid #ccc;
	border-top: 1px solid #ccc;
}
#emailVerified  .imagesboxs img {
	margin: 0 auto;
	display: block;
	width: 100%;
	max-width: 350px;
	padding: 15px;
}

#emailVerified .btnbox .btn.btn-green {
	width: 100%;
	border-radius: 5px !important;
}

#emailVerified  .btnbox {
	padding: 15px;
}






#notfound {
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
	padding: 20px;
	text-align: center;
	width: 100%;
}
#notfound p {
	color: #000;
	font-size: 18px;
	font-weight: 700;
	line-height: normal;
	margin-bottom: 20px;
}
#notfound .btn {
	border-radius: 5px;
}


.authboxs {
	width: 100%;
	display: block;
}
.logoimgs {
	max-width: 180px;
	display: block;
	width: 100%;
	margin-bottom: 50px;
}

.buttons.links {
	border: none !important;
	background: transparent !important;
	outline: none !important;
}


.cmpingbox {
	position: relative !important;
	overflow: hidden !important;
	width: 70px !important;
	height: 70px !important;
	border: 1px solid #cccc;
	border-radius: 50%;
	display: block;
}
.cmpingbox .cmpimg {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 100%;
	display: block;
}




.tablescards {
	padding: 15px;
	display: none;
}
.cardstbs {
	background-color: #fff;
	border-radius: 7px;
	box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15) !important;
	padding: 15px;
	margin-bottom: 15px;
}
.cmpdata {
	padding: 15px 0;
}
.cmpdata p {
	display: flex;
	margin: 5px 0;
}
.cmpdata p b {
	flex: 0 0 150px;
	max-width: 150px;
}


.pagination-controls {
	display: flex;
	align-items: center;
}
.pagination-controls span {
	padding: 0 10px;
	font-size: 15px;
}
.pagination-controls .btn.btn-primary {
	background: #2aac8e;
	border: none !important;
	padding: 5px 20px;
	border-radius: 39px;
	font-size: 15px;
}

#payment.cmpdata p b {
	flex: 0 0 50%;
	max-width: 50%;
	padding-right: 5px;
}


#deshboard div[data-column-id="1"] {
	min-width:120px !important;
	max-width: 120px !important;
}
#deshboard div[data-column-id="3"] {
	min-width:300px !important;
	max-width: 300px !important;
}

#paymenth div[data-column-id="1"] {
	min-width: 150px !important;
	max-width: 150px !important;
}
#paymenth div[data-column-id="4"] {
	min-width: 180px !important;
	max-width: 180px !important;
}
#paymenth div[data-column-id="5"] {
	min-width: 150px !important;
	max-width: 150px !important;
}
#paymenth div[data-column-id="6"] {
	min-width: 120px !important;
	max-width: 120px !important;
}


.Links {
	color: #2aac8e;
}



.fade.alert.alert-danger.alert-dismissible.show {
	position: fixed;
	max-width: 450px;
	z-index: 1111111;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: calc(100% - 30px);
	background: #fff !important;
	border-radius: 12px;
}
.overly {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 111111;
	background: rgba(0,0,0,0.5);
}

.alert-heading.h4 {
	color: #000;
	font-size: 20px;
	font-family: 'Poppins-Bold';
	padding-right: 50px;
	
}
.fade.alert.alert-danger.alert-dismissible.show .buttons {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 -10px;
}
.fade.alert.alert-danger.alert-dismissible.show .buttons div {
	padding: 0 10px;
}
.fade.alert.alert-danger.alert-dismissible.show .buttons div .btn {
	width: 110px !important;
	min-width: 110px !important;
	padding: 10px !important;
}



.errorboxs {
	position: absolute;
	z-index: 111;
	width: 220px;
	top: 61px;
}
.errorboxs i, .errorboxs svg {
	color: #000;
	font-size: 25px;
	position: absolute;
	top: -16px;
	left: 40px;
}
.errorbox {
	background: #000;
	padding: 15px;
	border-radius: 5px;
}
.passgrids {
	display: flex;
}
.passgrids p:first-child {
	flex: 0 0 160px;
	max-width: 160px;
}
.passgrids p {
	color: #fff;
	margin: 0;
	padding: 2px 0;
	font-size: 14px;
}
.passgrids p.red {
	color: red;
}
.passgrids p.green {
	color: green;
}
.passgrids.bottomg {
	border-top: 1px solid #fff;
	margin-top: 5px;
	padding-top: 5px;
}




.sscard {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	color: #fff !important;
	padding: 5px 12px;
	box-shadow: 0px 0px 4px #ccc;
	overflow: hidden;
	pointer-events: none;
}
.sscard.green {
	background-color: #08a88a;
}
.sscard.red {
	background-color: red;
}
.sscard svg, .sscard i {
	font-size: 19px;
	display: block;
	margin-right: 6px;
}





.menu-icon {
	display: block;
	height: auto;
	padding: 0;
	width: 30px;
	background: transparent;
	position: absolute;
	top: 20px;
	left: 15px;
	border: none !important;
}

.menu-icon span {
	display: block;
	margin: 4px 0;
	background: #fff;
	height: 3px;
}
.logo-box img {
	margin: 0;;
	margin-left: 45px;
}


@media (min-width: 1251px) {
	.leftnav {
		margin-left: -230px;
		transition-duration: 700ms;
	}
	.leftnav.open {
		margin-left: 0px;
		transition-duration: 700ms;
	}
}


.footer-block {
	background: #2e7161;
	padding: 15px;
}
.footer-block .address {
	display: flex;
	align-items: center;
	justify-content: center;
}
.footer-block .address .icons {
	border: 1px solid #fff;
	width: 30px;
	height: 30px;
	text-align: center;
	border-radius: 30px;
	color: #fff;
	padding-top: 2px;
	margin-right: 10px;
	flex: 0 0 30px;
}
.footer-block .address p {
	color: #fff;
	margin: 0;
}
.footer-block .address p a {
	color: #fff;
	text-decoration: underline;
}

.pdfWrapper {
	width: 100%;
	height: 100vh;
	overflow-y: auto;
  }
  
  .pdfCanvas {
	display: block;
	margin: 0 auto;
  }


  .suceessmeg {
	padding: 60px 0 80px 0;
	max-width: 95%;
	margin: 0 auto;
}

.suceessmeg .btngroup {
	padding: 30px 0;
	text-align: center;
}
.suceessmeg .btngroup .btn.btn-green {
	margin: 5px 0;
}
.paycdr {
	padding: 20px;
}

.suceessmeg .paycdr h4 {
	font-size: 23px;
}