@charset "utf-8";
/* CSS Document */

.lastlogin.rdb {
	display: none;
}
.res.userinfoboxs {
	display: none;
}

.logoresbox img {
	max-width: 140px;
	display: block;
	width: 100%;
}
.logoresbox {
	padding-bottom: 15px;
}
@media (max-width: 1300px) 
{

	.leftnav {
		max-width: 205px;
		flex: 0 0 205px;
	}
	.logo-box {
		flex: 0 0 205px;
		max-width: 205px;
	}
}
@media (max-width: 1250px) 
{
	.btns.rrr {
		margin-top: 15px;
	}
	.logo-box {
		background: transparent;
	}
	.lastlogin {
		display: none;
	}
	.header-block li .lastlogin.rdb {
		display: block;
	}
	#header .profile {
		display: none;
	}
	.rightnav ul {
		flex: 0 0 100%;
	}

	.menu-icon {
		display: block;
		height: auto;
		padding: 0;
		width: 30px;
		background: transparent;
		position: absolute;
		top: 20px;
		left: 15px;
		border: none !important;
	}
	.menu-icon span {
		display: block;
		margin: 4px 0;
		background: #fff;
		height: 3px;
	}
	.logo-box img {
		margin: 0;
		max-width: 130px;
		margin-left: 45px;
	}
	.rightnav {
		padding-left: 0;
	}
	.leftnav {
		width: 280px;
		position: fixed;
		top: 0px;
		left: -280px;
		z-index: 11111;
		height: 100vh;
		overflow-y: auto;
		transition-duration: 700ms;
		max-width: 100%;
	}
	.leftnav.open {
		left: 0px;
		transition-duration: 700ms;
	}
	.res.userinfoboxs {
		background: #2aac8e;
		display: block;
		padding: 15px;
	}
	.res.userinfoboxs .userinfo p {
		margin: 0;
		color: #fff;
		font-family: 'Poppins-Medium';
	}
	.res.userinfoboxs .userinfo h6 {
		color: #fff;
		font-size: 18px;
		margin-bottom: 15px;
	}
	.res.userinfoboxs .btns .btn.btn-white {
		width: 100%;
		border-radius: 5px;
	}

	.contents {
		padding: 20px 15px;
	}
	.pagetitle h1 {
		font-size: 22px;
	}
	.pagetitle {
		padding-bottom: 10px;
	}

	#overly {
		background: rgba(0,0,0,0.5);
		position: fixed;
		top: 0;
		left: 0;
		display: none;
		right: 0;
		bottom: 0;
		z-index: 1111;
		width: 100%;
		height: 100%;
	}
	#overly.open {
		display: block;
	}

	#heds.logo-box img {
		margin: 0px !important;
	}
	#heds.logo-box ~ .rightnav ul li:last-child {
		display: block !important;
	}
}
@media (max-width: 1200px) 
{
	.auth-froms {flex: 0 0 500px; max-width: 500px;}
	.auth-imgshap {max-width: calc(100% - 500px);}
}
@media (max-width: 1024px) 
{	
	.auth-froms {padding: 50px 20px;}

	.rightnav ul li:last-child {display: none;}
	.rightnav ul li.btns {
		padding: 0;
		flex: 0 0 133px;
	}
	.rdt_TableCell {
		min-width: 200px !important;
	}

	.resdnone-tab {
		display: none;
	}
	.tablescards {
		display: block;
	}
}

@media (max-width: 768px) 
{
	.col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9 
	{
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.order-md-1, .order-md-2 {-ms-flex-order: inherit !important; order: inherit !important;}

	.auth-block {
		display: flex;
		width: 100%;
		min-height: 100vh;
		align-items: center;
		background: #edf3fc;
		justify-content: center;
		padding: 20px;
	}
	.auth-imgshap {
		display: none;
	} 
	.auth-froms {
		padding: 20px;
		background: #fff;
		max-width: 450px;
		width: 100%;
		border-radius: 15px;
		box-shadow: 0px 0px 4px #ccc;
		flex: 0 0 100%;
	}

	.cardslist-count [class*="col-md"] {
		margin-bottom: 15px;
	}
	.cardslist-count [class*="col-md"]:last-child {
		margin-bottom: 0px;
	}
	.cards-hedar h4 {
		font-size: 18px;
	}
	.cardsbox {
		padding: 15px;
	}
}

@media (max-width: 640px) 
{	

	.cmpingbox {
		margin: 0 auto;
	}
	.titles h2 {
		font-size: 22px;
	}

	.rightnav .lastlogin.rdb {
		display: none;
	}
	.header-block {
		flex-wrap: wrap;
	}
	
	.header-block .lastlogin.rdb {
		display: block;
		margin: 0;
		flex: 0 0 100%;
		padding: 0 15px 8px 15px;
		color: #fff;
		margin-top: -5px;
	}
	.header-block li .lastlogin.rdb {
		display: none;
	}
	
	.usersinfos p {
		font-size: 15px;
		margin-bottom: 15px;
		display: flex;
	}
	.usersinfos p b {
		flex: 0 0 110px;
		max-width: 110px;
	}
	.pagetitle h1 {
		font-size: 20px;
		letter-spacing: normal;
	}
	.label.auth-label {
		white-space: nowrap;
		overflow: hidden;
		width: 80%;
	}
	.usersinfos p:first-child {
		display: block;
	}
	label {
		font-size: 15px;
	}
	.credits h6 {
		font-size: 15px;
	}
	.select.input.v-input {
		padding-right: 32px !important;
	}
	.input.v-input {
		font-size: 15px;
		padding: 25px 15px 15px 44px;
	}
	.label.auth-label {
		left: 44px !important;
	}
	#contents h1 {
		font-size: 25px;
	}
	#contents h4 {
		margin-top: 20px;
		font-size: 20px;
	}

	.notes {
		font-size: 14px;
		line-height: 18px;
	}
}

@media (max-width: 480px) 
{

	.control.is-flex.space {
		display: block;
	}
	.rightnav ul li.btns {
		display: none;
	}

	.cards-hedar {
		display: block;
	}
	.cards-hedar .btnsbox {
		margin-top: 15px;
	}
	.rdioboxs.d-flex {
		display: block !important;
	}
	.rdioboxs.d-flex .inputrdio {
		padding-left: 0;
	}
	.buttonboxs.group {
		display: block;
	}
	.buttonboxs.group .btn {
		width: 100%;
		margin: 0px !important;
		margin-top: 10px !important;
	}
	.cards-body .btn.btn-green {
		width: 100%;
	}
}