@charset "utf-8";

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Regular.woff2') format('woff2'),
        url('Poppins-Regular.woff') format('woff'),
        url('Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('Poppins-Medium.woff2') format('woff2'),
        url('Poppins-Medium.woff') format('woff'),
        url('Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('Poppins-SemiBold.woff2') format('woff2'),
        url('Poppins-SemiBold.woff') format('woff'),
        url('Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('Poppins-Bold.woff2') format('woff2'),
        url('Poppins-Bold.woff') format('woff'),
        url('Poppins-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}